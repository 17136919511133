:where(.css-dev-only-do-not-override-j9bb5n).ant-table-wrapper .ant-table-thead >tr>th {
    background-color: #eee !important;
    border: 1px solid #6a77d5 !important;
}

:where(.css-dev-only-do-not-override-j9bb5n).ant-table-wrapper .ant-table.ant-table-bordered >.ant-table-container >.ant-table-content >table >tbody>tr>td ,
:where(.css-dev-only-do-not-override-j9bb5n).ant-table-wrapper .ant-table.ant-table-bordered >.ant-table-container >.ant-table-body >table >tbody>tr>td{
    border-left: 1px solid #6a77d5 !important;
    border-right: 1px solid #6a77d5 !important;
    border-bottom: 1px solid #6a77d5 !important;
}

.editable-row .ant-form-item-explain {
    position: absolute;
    top: 100%;
    font-size: 12px;
}